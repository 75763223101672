* {
  font-family: 'Switzer', sans-serif;
}

.PrimoryBtn {
  /* background-color: black; */
  padding: 15px;
  border-radius: 60px;
  color: #FFFFFF;
}

.PrimoryBtn:hover {
  border: 2px solid #FFFFFF;
  outline: 2px solid #000000;
}

.btn-Disable-removedBorder:hover {
  border: none;
  outline: none;
}

.App-Bg {
  background-color: #fafafa;
  min-height: 100vh;
  /* -webkit-backface-visibility: hidden; */
  scroll-behavior: smooth;
}

@media screen and (min-width: 992px) {
  .App-Bg {
    background: #fafafa url("./assets/images/bg.svg");
    background-repeat: no-repeat;
    background-size: 600px 600px;
    background-position: right top;
  }
}

/* Slider style */
.rc-slider {
  height: 0px !important;
  padding: 0 !important;
  margin-bottom: 70px;
}

.rc-slider-rail {
  background-color: #d1d1d1 !important;
  border-radius: 0px !important;
}

.rc-slider-track {
  background-color: #fcaf3b !important;
  border-radius: 0px !important;
}

.direct-track-color .rc-slider-track {
  background-color: #f0faea !important;
  border-radius: 0px !important;
}

.four-hours-track-color .rc-slider-track {
  background-color: #c2ebac !important;
  border-radius: 0px !important;
}

.one-day-track-color .rc-slider-track {
  background-color: #fedba8 !important;
  border-radius: 0px !important;
}

.two-day-track-color .rc-slider-track {
  background-color: #fedba8 !important;
  border-radius: 0px !important;
}

.one-week-track-color .rc-slider-track {
  background-color: #feb1b1 !important;
  border-radius: 0px !important;
}

.one-month-track-color .rc-slider-track {
  background-color: #fc3b3b !important;
  border-radius: 0px !important;
}

.disable-track-color .rc-slider-track {
  background-color: #d1d1d1 !important;
  border-radius: 0px !important;
}

.rc-slider-handle {
  position: absolute;
  width: 24px !important;
  height: 24px !important;
  margin-top: -10px !important;
  background: #ededed url("./assets/icons/slider-icon.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 0px solid #000 !important;
  border-radius: 8px !important;
  cursor: pointer;
  cursor: grab;
  opacity: 1 !important;
  touch-action: pan-x;
}

.reverse-range-slider .rc-slider-track {
  background-color: #fcaf3b !important;
  border-radius: 0px !important;
}

.rc-slider-handle-1 {
  position: absolute;
  width: 24px !important;
  height: 24px !important;
  margin-top: -10px !important;
  background: #fafafa url("./assets/icons/slider-handle-icon.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 0px solid #c1c1c1 !important;
  border-radius: 8px !important;
  cursor: pointer;
  cursor: grab;
  opacity: 1 !important;
  touch-action: pan-x;
}

.rc-slider-handle:active,
.rc-slider-handle:focus-visible {
  box-shadow: none !important;
}

.rc-slider-step {
  width: 99% !important;
}

.rc-slider-dot {
  position: absolute !important;
  bottom: 0 !important;
  top: 0px !important;
  margin-left: 0px !important;
  margin-top: 4px !important;
  width: 2px !important;
  height: 10px !important;
  vertical-align: middle !important;
  background-color: #d1d1d1 !important;
  border: 1.5px solid #d1d1d1 !important;
  border-radius: 0px !important;
  cursor: pointer !important;
  transform: none !important;
}

.rc-slider-tooltip-content {

  margin-top: 40px;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  transform: rotate(180deg);
  bottom: -32px !important;
}

.rc-slider-tooltip-inner {
  padding: 6px 10px !important;
  background-color: #000 !important;
  margin-bottom: -200px !important;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #000 !important;
}

.rc-slider-disabled {
  background-color: transparent !important;
  max-width: 350px !important;
}

.rc-slider-disabled .rc-slider-handle {
  position: absolute;
  width: 24px !important;
  height: 24px !important;
  margin-top: -10px !important;
  background: #fafafa url("./assets/icons/slider-handle-disable-icon.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 0px solid #c1c1c1 !important;
  border-radius: 8px !important;
  cursor: pointer;
  cursor: grab;
  opacity: 1 !important;
  touch-action: pan-x;
}

/* Notification bullets style */
.notification-list ul li::marker {
  color: #3ba8fc !important;
}

/* remove outline from input */
input[type="text"]:focus-visible {
  outline: none;
  caret-color: #3ba8fc;
}

input[type="password"]:focus-visible {
  outline: none;
  caret-color: #3ba8fc;
}

/* Radio button style */
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border: 1px solid #ededed;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="radio"]:checked {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fcaf3b;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #000;
  /* CanvasText; */
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

/* checkbox style */
input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 28px !important;
  height: 20px !important;
  /* border: 1px solid #ededed; */
  border-radius: 4px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fcaf3b;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 28px !important;
  height: 20px !important;
  border: none;
  border-radius: 4px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 11px;
  height: 11px;
  border-radius: 4px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #000;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  /* CanvasText; */
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

/* matrix-chart style */
.matrix-chart {
  display: flex;
  align-items: center;
  width: 100%;
}

.custom-tooltip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 111px;
  padding: 6px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.custom-textarea {
  width: 100%;
  height: 96px;
  padding: 8px 16px;
  box-sizing: border-box;
  /* border: 1px solid #ededed; */
  border-radius: 8px;
  background-color: #fafafa;
  font-size: 16px;
  resize: none;
}

/* react-date-picker style */
.react-date-picker__wrapper {
  /* flex-direction: row-reverse; */
  border: none !important;
  width: 180px;
}

.react-date-picker__calendar {
  inset: auto 0px 100% auto !important;
}

.react-calendar {
  width: 350px !important;
  background: white;
  border-radius: 8px !important;
  border: 1px solid #c1c1c1 !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-date-picker__clear-button {
  display: none;
}

.react-date-picker__inputGroup__divider {
  color: #959595;
}

.react-date-picker__inputGroup__input:invalid {
  background: none !important;
}

.react-date-picker__inputGroup__input:focus-visible {
  outline: none !important;
}

.customDatePicker .react-calendar {
  min-height: 310px;
}

.react-date-picker__button:enabled {
  cursor: pointer;
    width: 200px;
    position: relative;
    left: -16px;
}
.react-date-picker {
  padding: 0 15px;
}
/* .react-date-picker__button {
  padding: 4px 0 4px 15px;
} */

.react-date-picker__inputGroup{
  position: absolute;
  padding: 10px 0px;
  left: 40px;
}

.app-loader {
  width: 100%;
  /* min-height: 100vh; */
}

.app-loader svg {
  width: 60px;
  height: 60px;
  margin: auto;
  inset: 0;
  position: absolute;
  z-index: 1056;
}

.app-elip {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* min-width: 160px; */
  max-width: 165px;
  word-break: break-all;
}

.app-elip-mg {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* min-width: 160px; */
  /* max-width: 165px; */
  word-break: break-all;
}

.app-elip-hsc {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* min-width: 160px; */
  max-width: 49%;
  word-break: break-all;
}

.app-elip-task {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-width: 255px;
  max-width: 255px;
  word-break: break-all;
}

.org-list-elip {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-width: 145px;
  /* max-width: 165px; */
  word-break: break-all;
}

.user-details-elip {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.user-role-elip {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  min-width: 45%;
}

.app-video .video-js {
  background-color: none !important;
}

.video-js.vjs-fluid {
  object-fit: cover !important;
  width: 100% !important;
  max-width: 700px !important;
}

.role-limit-elip {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-width: 100px;
  max-width: 100px;
  word-break: break-all;
}

.manage-risk-area-app-elip {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* min-width: 160px; */
  max-width: 215px;
  word-break: break-all;
}

.welcome-text p {
  font-family: "Arial", sans-serif !important;
}

.font-family-arial {
  font-family: "Arial", sans-serif !important;
}

/* antd slider */
.ant-slider .ant-slider-rail {
  background-color: #d1d1d1 !important;
  border-radius: 0px !important;
  width: 100.8%;
}

.ant-slider-track {
  background-color: #fcaf3b !important;
  border-radius: 0px !important;
}

.direct-track-color .ant-slider-track {
  background-color: #f0faea !important;
  border-radius: 0px !important;
}

.four-hours-track-color .ant-slider-track {
  background-color: #c2ebac !important;
  border-radius: 0px !important;
}

.one-day-track-color .ant-slider-track {
  background-color: #fedba8 !important;
  border-radius: 0px !important;
}

.two-day-track-color .ant-slider-track {
  background-color: #fedba8 !important;
  border-radius: 0px !important;
}

.one-week-track-color .ant-slider-track {
  background-color: #feb1b1 !important;
  border-radius: 0px !important;
}

.one-month-track-color .ant-slider-track {
  background-color: #fc3b3b !important;
  border-radius: 0px !important;
}

.ant-slider .ant-slider-handle {
  position: absolute;
  width: 24px !important;
  height: 24px !important;
  margin-top: -7px !important;
  background: #ededed url("./assets/icons/slider-icon.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 0px solid #000 !important;
  border-radius: 8px !important;
  cursor: pointer;
  cursor: grab;
  opacity: 1 !important;
  touch-action: pan-x;
}

.ant-slider .ant-slider-handle::after {
  display: none;
}

.ant-slider .ant-slider-handle::before {
  display: none;
}

.ant-slider-horizontal .ant-slider-handle-1 {
  position: absolute;
  width: 24px !important;
  height: 24px !important;
  margin-top: -10px !important;
  background: #fafafa url("./assets/icons/slider-handle-icon.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 0px solid #c1c1c1 !important;
  border-radius: 8px !important;
  cursor: pointer;
  cursor: grab;
  opacity: 1 !important;
  touch-action: pan-x;
}

.ant-slider .ant-slider-mark {
  font-size: 12px;
  margin-top: 10px !important;
}

.ant-slider-horizontal.ant-slider-with-marks {
  margin-bottom: 50px;
}

.ant-slider .ant-slider-dot {
  position: absolute !important;
  bottom: 0 !important;
  top: 0px !important;
  margin-left: 0px !important;
  margin-top: 4px !important;
  width: 2px !important;
  height: 10px !important;
  vertical-align: middle !important;
  background-color: #d1d1d1 !important;
  border: 1.5px solid #d1d1d1 !important;
  border-radius: 0px !important;
  cursor: pointer !important;
  transform: none !important;
}

.ant-slider-disabled {
  background-color: transparent !important;
  max-width: 350px !important;
}

.ant-slider-disabled .ant-slider-handle {
  position: absolute;
  width: 24px !important;
  height: 24px !important;
  margin-top: -7px !important;
  background: #fafafa url("./assets/icons/slider-handle-disable-icon.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 0px solid #c1c1c1 !important;
  border-radius: 8px !important;
  cursor: pointer;
  cursor: grab;
  opacity: 1 !important;
  touch-action: pan-x;
}

.ant-slider.ant-slider-disabled .ant-slider-track {
  background-color: #d1d1d1 !important;
  border-radius: 0px !important;
}

.ant-slider.ant-slider-disabled .ant-slider-rail {
  background-color: #d1d1d1 !important;
  border-radius: 0px !important;
  width: 100.8%;
}

.ant-slider-disabled .ant-slider-dot {
  position: absolute !important;
  bottom: 0 !important;
  top: 0px !important;
  margin-left: 0px !important;
  margin-top: 4px !important;
  width: 2px !important;
  height: 10px !important;
  vertical-align: middle !important;
  background-color: #d1d1d1 !important;
  border: 1.5px solid #d1d1d1 !important;
  border-radius: 0px !important;
  cursor: pointer !important;
  transform: none !important;
}